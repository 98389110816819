import React, { MouseEvent } from 'react';
import Styled from './index.styled';

type TabProps = {
	id: string
	href: string
	tabRef: any
	isSelected: boolean
	tabIndex: number
	onClick: (event: MouseEvent) => void
	onFocus: (event: React.FocusEvent<HTMLAnchorElement>) => void
	onKeyDown: (event: React.KeyboardEvent<HTMLAnchorElement>) => void
	data: {
		tabTitle: string
		subText?: string
		dataGACategory: string
		dataGAAction: string
		dataGALabel: string
	}
};

const Tab = ({
	id,
	href,
	tabRef,
	isSelected,
	tabIndex,
	onClick,
	onFocus,
	onKeyDown,
	data,
}: TabProps) => {
	const { tabTitle, subText, dataGACategory, dataGAAction, dataGALabel } = data;

	return (
		<Styled.Tab role="presentation" data-active={isSelected}>
			<Styled.Tab__Link
				as="a"
				href={href}
				role="tab"
				ref={tabRef}
				id={id}
				tabIndex={tabIndex}
				onClick={onClick}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				aria-selected={isSelected}
				data-ga-category={dataGACategory}
				data-ga-action={dataGAAction}
				data-ga-label={dataGALabel}
			>
				{tabTitle}

				{subText && (
					<Styled.Tab__SubText as="span">{subText.split('(')[0]}</Styled.Tab__SubText>
				)}
			</Styled.Tab__Link>
		</Styled.Tab>
	);
};


export default Tab;
