/* eslint-disable @typescript-eslint/no-var-requires */
const NodeCache = require('node-cache');

let cache = null;

exports.start = (done) => {
	if(cache) return done();
	cache = new NodeCache({stdTTL: 10});
};

exports.instance = () => cache;

exports.setInstance = (name, content) => {
	cache.set(name, content);
};

exports.clearCache = () => {
	cache.flushAll();
};