import styled, {css} from 'styled-components';
import {gap} from '@/styles/settings/spacing';
import {above} from '@/styles/tools/media';
import breakpoints from '../../../styles/settings/breakpoints';

const ContactForm = styled.form`
	width: 100%;
	margin: ${gap[0]} auto;
	outline: none;
`;

const ContactForm__fieldset = styled.fieldset`
	border: 0;
	margin: ${gap[0]};
	padding: ${gap[0]};
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const ContactForm__legend = styled.legend`
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	opacity: 0;
	overflow: hidden;
`;

type FieldWrapperProps = {
	halfWidth?: boolean;
};

const ContactForm__FieldWrapper = styled.div<FieldWrapperProps>`
	width: 100%;
	
	${(props) => css`
		${above(
			breakpoints[960],
			() => css`
			width: ${() => (props.halfWidth ? '48%' : '100%')};
		`)}
	`};
`;

const ContactForm__ButtonWrapper = styled.div`
	width: 100%;
`;

export default {
	ContactForm,
	ContactForm__fieldset,
	ContactForm__legend,
	ContactForm__FieldWrapper,
	ContactForm__ButtonWrapper,
};
