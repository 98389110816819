/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-var-requires */
import fetch from 'node-fetch';

const contentCache = require('./node-cache');

const getPostOptions = data => ({
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
	body: JSON.stringify(data),
});

export const apiGet = async url => {
	const content = await fetch(url);
	const apiContent = await content.json();

	return apiContent;
};

export const apiPost = async (url, data) => {
	const response = await fetch(url, getPostOptions(data));
	return response;
};
