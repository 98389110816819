import styled, { css } from 'styled-components';
import LinkStandard from '../../atoms/LinkStandard';
import { InnerGrid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { smBodyLight, smBodyBook, smBodyRegular, bigTitleRegular } from '../../atoms/Typography/index.styled';

type TabbedComponentProps = {
	isFormComponent?: boolean;
};

const Panel = styled(InnerGrid)`

	align-items: start;
	grid-column: 1 / -1;
	margin-top: ${gap[32]};
	outline: none;

	&[hidden] {
		display: none;
	}

	> a {
		grid-column: 1 / -1;
		grid-row: 3;
		justify-self: start;
		margin-top: ${gap[32]};
	}

	${above(breakpoints[960], () => css`
		margin-top: ${gap[32]};

		> a {
			margin-top: ${gap[32]};
		}
	`)}
`;

const Panel__Inner = styled.div`
	grid-column: 1 / -1;

	${above(breakpoints[960], () => css`
		grid-column-end: span 6;
	`)}
`;

const Panel__RightPanel = styled.div<TabbedComponentProps>`

	${(props) => props.isFormComponent && css`

		${below(breakpoints[960], () => css`
			grid-column: 1 / -1;
		`)}
	`}

	& > picture {
		display: none;
		padding-top: ${aspectRatio('2:3')};
	}

	${above(breakpoints[960], () => css`
		grid-column: 8 / -1;
		margin-bottom: ${gap[24]};

			& > picture {
				display: block;
				box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
			}
	`)}

	${above(breakpoints[1440], () => css`
		& > picture {
			margin-bottom: ${gap[32]};
			padding-top: ${aspectRatio('1:1')};
			box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
		}
	`)}
`;

const Panel__Cards = styled.ul`
	grid-column: 1 / -1;
	grid-row: 2;
	margin-top: ${gap[32]};
	padding-left: 0;
	list-style-type: none;

	${above(breakpoints[960], () => css`
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: ${gap[48]};
		margin-top: ${gap[64]};
	`)}
`;

const Panel__CardsItem = styled.li`
	margin-bottom: ${gap[32]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}

	${above(breakpoints[960], () => css`
		grid-column-end: span 4;
		margin-bottom: ${gap[0]};
		padding-bottom: ${gap[32]};
	`)}
`;

const Panel__Title = styled(bigTitleRegular)`
	color: var(--primaryForeground);

	${above(breakpoints[960], () => css`
		white-space: nowrap;
	`)}
`;

const Panel__SubText = styled(smBodyBook)`
	margin-top: ${gap[4]};

	${above(breakpoints[960], () => css`
		margin-top: ${gap[8]};
	`)}
`;

const Panel__Body = styled(smBodyLight)`
	margin-top: ${gap[24]};
`;

const Panel__List = styled.ul`
	margin-top: ${gap[32]};
	padding-left: 0;
	list-style-type: none;
`;

const Panel__Item = styled.li`
	margin-bottom: ${gap[24]};

	svg {
		display: none;
	}

	${above(breakpoints[960], () => css`
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		margin-bottom: ${gap[24]};

		svg {
			display: initial;
			grid-column: 1;
			grid-row: 1 / span 2;
			width: 64px;
			height: auto;
			margin-right: ${gap[32]};
		}
	`)}
`;

const Panel__ItemTitle = styled(smBodyRegular)`
	${above(breakpoints[960], () => css`
		grid-column: 2;
	`)}
`;

const Panel__ItemSubText = styled(smBodyBook)``;

const Panel__ItemBody = styled(smBodyLight)`
	margin-top: ${gap[16]};

	${above(breakpoints[960], () => css`
		grid-column: 2;
	`)}
`;

const Panel__Link = styled(LinkStandard)``;

export default {
	Panel,
	Panel__Inner,
	Panel__RightPanel,
	Panel__Cards,
	Panel__CardsItem,
	Panel__Title,
	Panel__SubText,
	Panel__Body,
	Panel__List,
	Panel__Item,
	Panel__ItemTitle,
	Panel__ItemSubText,
	Panel__ItemBody,
	Panel__Link
};
