import React, { useRef, useState, MouseEvent } from 'react';

import { ContactTypes } from '../../../types';

import Tab from '../../atoms/Tab';
import TabPanel from '../../molecules/TabPanel';
import { pushEvent } from '../../../lib/tracking';
import Styled from './index.styled';

type TabPanelTypes = {
	id: string;
	panelTitle: string;
	body: string;
	cardGroupSize: number;
	showMoreCardsButtonText: string;
};

type ListTypes = {
	id: string;
	icon?: string;
	title: string;
	body?: string;
	subText?: string;
};

type TabTypes = {
	id: string;
	tabTitle: string;
	panelTitle: string;
	subText?: string;
	body: string;
	dataGAAction: string;
	dataGALabel: string;
	dataGACategory: string;
	list?: ListTypes[];
	link?: any;
	cards?: any;
	image?: any;
	form?: ContactTypes;
	cardGroupSize: number;
	showMoreCardsButtonText: string;
};

type TabbedTypes = {
	backgroundColor?: string;
	title: string;
	body?: string;
	tabs: TabTypes[];
	anchorId?: string;
};

const Tabbed = ({ title, body, backgroundColor, tabs, anchorId }: TabbedTypes) => {
	const tabRef = useRef(new Map()).current;
	const panelRef = useRef(new Map()).current;
	const [current, setCurrent] = useState(0);

	const handleClick = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLAnchorElement;
		const id = target.getAttribute('href');
		if (typeof id === 'string' && id.length) {
			const index = parseInt(id.split('#section')[1], 10);

			event.preventDefault();
			target.focus();
			setCurrent(index);
		}
	};

	const handleFocus = (event: React.FocusEvent<HTMLAnchorElement>) => {
		pushEvent(event);
	};

	const nextTab = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
		if (current === tabs.length - 1) return;

		event.preventDefault();
		tabRef[current + 1].focus();
		setCurrent(current + 1);
	};

	const previousTab = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
		if (current === 0) return;

		event.preventDefault();
		tabRef[current - 1].focus();
		setCurrent(current - 1);
	};

	const focusPanel = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		panelRef[current].focus();
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
		const { key } = event;

		switch (key) {
			case 'ArrowRight':
				return nextTab(event);
			case 'ArrowLeft':
				return previousTab(event);
			case 'ArrowDown':
				return focusPanel(event);
			default:
		}
	};

	const renderTab = (tab, index: any) => {
		const id = `tab${index}`;
		const tabIndex: number = current === parseInt(index) ? 0 : -2;
		const isSelected: boolean = current === parseInt(index);
		const ref = (el: any) => (tabRef[index] = el);

		return (
			<Tab
				data={tab}
				href={`#section${index}`}
				tabIndex={tabIndex}
				onClick={handleClick}
				onFocus={handleFocus}
				onKeyDown={onKeyDown}
				tabRef={ref}
				id={id}
				isSelected={isSelected}
				key={`${tab.id}${index}`}
			/>
		);
	};

	const renderPanel = (panel: TabPanelTypes, index: any) => {
		const id = `section-${index}`;
		const isHidden: boolean = current !== parseInt(index);
		const ref = (el: any) => (panelRef[index] = el);

		return (
			<TabPanel
				data={panel}
				hidden={isHidden}
				id={id}
				index={parseInt(index)}
				key={`${panel.id}${index}`}
				panelRef={ref}
			/>
		);
	};

	return (
		<Styled.Tabbed
			backgroundColor={backgroundColor || 'secondaryBackground'}
			id={anchorId || ''}
		>
			<Styled.Title as="h2">{title}</Styled.Title>

			{body && <Styled.Body>{body}</Styled.Body>}

			<Styled.Tabs role="tablist">{tabs.map(renderTab)}</Styled.Tabs>

			{tabs.map(renderPanel)}
		</Styled.Tabbed>
	);
};

export default Tabbed;
