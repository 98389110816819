import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import { FormTypes, HiddenInputProps } from '@/types';

import { apiPost } from '@/lib/api';
import { pushEvent } from '@/lib/tracking';
import FormCheckbox, { CheckboxProps } from '../../atoms/FormCheckbox';
import FormTextarea, { TextareaProps } from '../../atoms/FormTextarea';
import FormHiddenInput from '../../atoms/FormHiddenInput';
import FormDropdown, { DropdownProps } from '../../atoms/FormDropdown';
import FormInput, { InputProps } from '../../atoms/FormInput';
import Button from '../../atoms/Button';
import Styled from './index.styled';
import { ConversionTracker } from '../../conversionTracker/ConversionTracker';

type ContactFormTypes = {
	setFormsubmitted: (arg: boolean) => void;
	formData: FormTypes;
	anchorId?: string;
};

type Input = {
	key: string;
	value: string;
};

const ContactForm = ({ setFormsubmitted, formData, anchorId }: ContactFormTypes) => {
	const [loading, setLoading] = useState(false);
	const [buttonEvent, setButtonEvent] = useState<MouseEvent | null>(null);
	const router = useRouter();

	const createPostData = (form: FormData) => {
		const { name, subject, emailType, emailAddressesToSend } = formData;
		const locations = form.getAll('Location').join(', ');
		const inputs: Input[] = [];

		for (const pair of form.entries() as any) {
			if (pair[1] !== '' && pair[0] !== 'Location') {
				inputs.push({ key: pair[0], value: pair[1] });
			}
		}

		if (locations.length > 0) {
			inputs.push({ key: 'Locations', value: locations });
		}

		return { name, subject, inputs, emailType, emailAddressesToSend };
	};

	const handleSubmit = async (event: React.FormEvent) => {
		const form = new FormData(event.target as HTMLFormElement);
		const postData = createPostData(form);

		event.preventDefault();
		setLoading(true);

		try {
			const response = await apiPost('/api/contact-form', postData);

			if (!response.ok) {
				throw new Error('Post returned an error');
			}
		} catch (e) {
			console.log('ERROR', e);
			setLoading(false);
			return;
		}
		const conversionTracker = new ConversionTracker(Cookies);
		conversionTracker.sendConversionEvent();

		if (buttonEvent) {
			pushEvent(buttonEvent);
		}

		setLoading(false);

		formData.confirmationPageRedirect
			? router.push(formData.confirmationPageRedirect)
			: setFormsubmitted(true);
	};

	const renderInput = (field: InputProps) => (
		<FormInput
			key={field.id}
			id={field.id}
			name={field.name}
			label={field.label}
			required={field.required}
			message={field.message ? field.message : undefined}
			pattern={field.pattern ? field.pattern : undefined}
			optionalText={field.optionalText ? field.optionalText : 'optional'}
		/>
	);

	const renderHiddenInput = (field: HiddenInputProps) => (
		<FormHiddenInput key={field.id} id={field.id} name={field.name} value={field.value} />
	);

	const renderDropdown = (field: DropdownProps) => {
		if (field.options) {
			return (
				<FormDropdown
					key={field.id}
					id={field.id}
					label={field.label}
					name={field.name}
					options={field.options}
					optionalText={field.optionalText ? field.optionalText : 'optional'}
					required={field.required}
					message={field.message ? field.message : undefined}
					pattern={field.pattern ? field.pattern : undefined}
					placeholderMessage={field.placeholderMessage ? field.placeholderMessage : ''}
				/>
			);
		}
	};

	const renderTextarea = (field: TextareaProps) => (
		<FormTextarea
			key={field.id}
			id={field.id}
			name={field.name}
			label={field.label}
			required={field.required}
			message={field.message ? field.message : undefined}
		/>
	);

	const renderCheckboxes = (field: CheckboxProps) => {
		if (field.checkboxes) {
			return (
				<FormCheckbox
					key={field.id}
					id={field.id}
					name={field.name}
					label={field.label}
					checkboxes={field.checkboxes}
				/>
			);
		}
	};

	const renderField = (field) => {
		switch (field.type) {
			case 'text':
				return renderInput(field);
			case 'textarea':
				return renderTextarea(field);
			case 'checkboxes':
				return renderCheckboxes(field);
			case 'hidden':
				return renderHiddenInput(field);
			case 'dropdown':
				return renderDropdown(field);
			default:
		}
	};

	return (
		<Styled.ContactForm
			action="/api/contact-form"
			method="post"
			onSubmit={handleSubmit}
			id={anchorId || 'enquire'}
		>
			<Styled.ContactForm__fieldset>
				<Styled.ContactForm__legend>Get in touch</Styled.ContactForm__legend>
				{formData?.fields.map((field, index) => (
					<Styled.ContactForm__FieldWrapper
						key={`${field.name}--${index}`}
						halfWidth={field.halfWidth}
					>
						{renderField(field)}
					</Styled.ContactForm__FieldWrapper>
				))}
				<Styled.ContactForm__ButtonWrapper>
					<Button
						buttonText={
							formData.button.text || formData.button.buttonText || 'Enquire today'
						}
						buttonCategory={formData.button.category || formData.button.buttonCategory}
						buttonAction={formData.button.action || formData.button.buttonAction}
						buttonLabel={formData.button.label || formData.button.buttonLabel}
						as="button"
						buttonType="secondary"
						loading={loading}
						loadingText="Sending"
						callback={(e) => setButtonEvent(e)}
					/>
				</Styled.ContactForm__ButtonWrapper>
			</Styled.ContactForm__fieldset>
		</Styled.ContactForm>
	);
};

export default ContactForm;
