import styled, { css } from 'styled-components';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyLight, xlTitleRegular } from '../../atoms/Typography/index.styled';

type TabbedProps = {
	backgroundColor: string
};

const Tabbed = styled(Container)<TabbedProps>`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};
	background-color: ${props => `var(--${props.backgroundColor})`};

	${above(breakpoints[960], () => css`
		padding-top: ${spacing[64]};
		padding-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		padding-top: ${spacing[80]};
		padding-bottom: ${spacing[80]};
	`)}
`;

const Title = styled(xlTitleRegular)`
	color: var(--primaryForeground);
`;

const Body = styled(smBodyLight)`
	max-width: 830px;
	margin-top: ${gap[24]};

	${above(breakpoints[960], () => css`
		margin-top: ${gap[32]};
	`)}
`;

const Tabs = styled.ul`
	position: relative;
	margin-top: ${gap[32]};
	padding-left: 0;
	list-style-type: none;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border: 0 solid transparent;
		border-left-width: 12px;
		border-right-width: 12px;
		border-top: 12px solid var(--primaryForeground);
		transform: translateX(-50%);
	}

	${above(breakpoints[960], () => css`
		display: flex;
		margin-top: ${gap[56]};
		border-bottom: 1px solid var(--primaryForeground);

		&::after {
			display: none;
		}
	`)}

`;

export default {
	Tabbed,
	Body,
	Title,
	Tabs
};
