import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, hover } from '../../../styles/tools/media';
import { smBodyLight, smBodyRegular } from '../Typography/index.styled';

const Tab = styled.li`
	position: relative;
	border-top: 1px solid var(--primaryForeground);
	border-right: 1px solid var(--primaryForeground);
	border-left: 1px solid var(--primaryForeground);

	&:last-child {
		border-bottom: 1px solid var(--primaryForeground);
	}

	${above(breakpoints[960], () => css`
		position: relative;
		display: inline-block;
		border-right: none;
		border-left: 1px solid var(--primaryForeground);

		&:last-child {
			border-right: 1px solid var(--primaryForeground);
			border-bottom: none;
		}
	`)}
`;

const Tab__Link = styled(smBodyRegular)`
	display: block;
	position: relative;
	padding: ${gap[16]} ${gap[12]};
	color: var(--primaryForeground);
	text-decoration: none;

	&:focus,
	&:active {
		outline: 4px solid var(--primaryAccent);
		z-index: 2;
	}

	&[aria-selected="true"] {
		background-color: var(--primaryForeground);
		color: var(--white);
		z-index: 1;
	}

	${above(breakpoints[960], () => css`
		padding: ${gap[12]} ${gap[24]};

		&[aria-selected="true"]::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				width: 0;
				height: 0;
				border: 0 solid transparent;
				border-left-width: 12px;
				border-right-width: 12px;
				border-top: 12px solid var(--primaryForeground);
				transform: translateX(-50%);
			}
	`)}

	${hover(() => css`
		transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

		&:hover {
			background-color: var(--primaryForeground);
			color: var(--white);
		}
	`)}
`;

const Tab__SubText = styled(smBodyLight)`
	display: block;
	margin-top: ${gap[4]};
	color: var(--black);

	${Tab__Link}[aria-selected="true"] & {
		color: var(--white);
	}

	${above(breakpoints[960], () => css`
		color: var(--primaryForeground);
	`)}

	${hover(() => css`
		transition: color;

		${Tab__Link}:hover & {
			color: inherit;
		}
	`)}
`;

export default {
	Tab,
	Tab__Link,
	Tab__SubText
};
