import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

import { ContactTypes } from '../../../types';

import Button from '../../atoms/Button';
import CustomImage from '../../atoms/CustomImage';
import LinkStandard from '../../atoms/LinkStandard';
import SVG from '../../atoms/SVG';
import Card from '../Card';
import { pushEvent } from '../../../lib/tracking';
import Contact from '../../organisms/Contact';
import ContactPardot from '../../organisms/ContactPardot';
import Styled from './index.styled';

type CtaTypes = {
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type ImageTypes = {
	src: string;
	alt: string;
	imageType?: string;
};

type LinkTypes = {
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type ListItemTypes = {
	id: string;
	icon: string;
	book?: string;
	body?: string;
};

type CardTypes = {
	id: string;
	hideImageOnMobile?: boolean;
	hideListOnMobile?: boolean;
	image: ImageTypes;
	title: string;
	subText?: string;
	copy?: string;
	location?: string;
	list: Array<ListItemTypes>;
	link: LinkTypes;
};

type PanelListTypes = {
	panelId: string;
	title: string;
	subText: string;
	body: string;
	icon: string;
};

type TabPanelProps = {
	id: string;
	hidden: boolean;
	panelRef: any;
	index: number;
	data: {
		panelTitle: string;
		subText?: string;
		body: string;
		list?: Array<PanelListTypes>;
		link?: LinkTypes;
		cards?: Array<CardTypes>;
		image?: ImageTypes;
		cta?: CtaTypes;
		form?: ContactTypes;
		cardGroupSize: number;
		showMoreCardsButtonText: string;
	};
};

const handleClick = (event: MouseEvent) => {
	pushEvent(event);
};

const renderCard = (card: CardTypes, index: number) => (
	<Styled.Panel__CardsItem key={`${card.id}${index.toString()}`}>
		<Card {...card} />
	</Styled.Panel__CardsItem>
);

const TabPanel = ({ id, hidden, panelRef, index, data }: TabPanelProps) => {
	const theme = useContext(ThemeContext);
	const [cardLimit, setCardLimit] = useState(data.cardGroupSize);

	const renderPanelList = ({ panelId, title, subText, body, icon }: PanelListTypes) => {
		const fill = theme?.primaryForeground;

		return (
			<Styled.Panel__Item key={panelId}>
				{icon && <SVG name={icon} fill={fill} />}
				<Styled.Panel__ItemTitle>
					{title}
					{subText && (
						<Styled.Panel__ItemSubText as="span">{subText}</Styled.Panel__ItemSubText>
					)}
				</Styled.Panel__ItemTitle>

				{body && <Styled.Panel__ItemBody>{body}</Styled.Panel__ItemBody>}
			</Styled.Panel__Item>
		);
	};

	return (
		<Styled.Panel
			as="section"
			id={id}
			role="tabpanel"
			tabIndex={-1}
			hidden={hidden}
			ref={panelRef}
			aria-labelledby={`tab${index}`}
		>
			<Styled.Panel__Inner>
				<Styled.Panel__Title as="h3">{data.panelTitle}</Styled.Panel__Title>

				{data.subText && <Styled.Panel__SubText>{data.subText}</Styled.Panel__SubText>}

				<Styled.Panel__Body id={`body${id}`}>{data.body}</Styled.Panel__Body>

				{data.list && (
					<Styled.Panel__List>{data.list.map(renderPanelList)}</Styled.Panel__List>
				)}

				{data.link && <LinkStandard {...data.link} />}
			</Styled.Panel__Inner>

			{data.cards && !(data.image || data.form) && (
				<>
					<Styled.Panel__Cards>
						{data.cards.slice(0, cardLimit).map(renderCard)}
					</Styled.Panel__Cards>
					{cardLimit < data.cards.length && (
						<Button
							callback={() => setCardLimit(cardLimit + data.cardGroupSize)}
							buttonText={data.showMoreCardsButtonText}
							buttonType="tertiary"
						/>
					)}
				</>
			)}

			{(data.image !== null || data.form !== null) && (
				<Styled.Panel__RightPanel isFormComponent={data.form !== null}>
					{data.image && !data.form && (
						<CustomImage {...data.image} width="200" height="300" />
					)}
					{data.form && (
						<>
							{!data.form?.pardot && <Contact {...data.form} isTabbedComponent />}
							{data.form?.pardot && (
								<ContactPardot {...data.form} isTabbedComponent />
							)}
						</>
					)}
				</Styled.Panel__RightPanel>
			)}

			{data.cta && (
				<Button
					buttonLink={data.cta.url}
					buttonText={data.cta.title}
					buttonCategory={data.cta.dataGACategory}
					buttonAction={data.cta.dataGAAction}
					buttonLabel={data.cta.dataGALabel}
					buttonType="tertiary"
					callback={handleClick}
				/>
			)}
		</Styled.Panel>
	);
};

export default TabPanel;
