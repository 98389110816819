import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import {
	xsBodyLight,
	smBodyBook,
	smBodyLight,
	mdBodyRegular,
	xlTitleRegular,
	smBodyRegular,
} from '../../atoms/Typography/index.styled';

type WithDividerTypes = {
	withDivider: boolean;
};

type TabbedComponentProps = {
	isTabbedComponent?: boolean;
};

const Contact = styled(Grid)<TabbedComponentProps>`

	${props =>
		props.isTabbedComponent &&
		css`
			${below(
				breakpoints[960],
				() => css`
					padding-left: 0px;
					padding-right: 0px;
				`
			)}
		`}

	margin-top: ${spacing[48]};
	margin-bottom: ${spacing[48]};

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${spacing[64]};
			margin-bottom: ${spacing[64]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			margin-top: ${spacing[80]};
			margin-bottom: ${spacing[80]};
		`
	)}
`;

const Content = styled.div<TabbedComponentProps>`
	grid-column: 1 / -1;
	${props =>
		!props.isTabbedComponent &&
		above(
			breakpoints[960],
			() => css`
				grid-column: 4 / -4;
			`
		)}
`;

const Inner = styled.div<WithDividerTypes>`
	${props =>
		props.withDivider &&
		css`
			margin-bottom: ${gap[32]};

			${above(
				breakpoints[960],
				() => css`
					margin-bottom: ${gap[48]};
				`
			)}
		`}
`;

const Title = styled(xlTitleRegular)`
	color: var(--primaryForeground);
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

const Body = styled(smBodyLight)`
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

const BodyBook = styled(smBodyBook)`
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

const Form = styled.div``;

const FormTitle = styled(mdBodyRegular)`
	margin-bottom: ${gap[32]};
	color: var(--primaryForeground);

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[40]};
		`
	)}
`;

const PhoneNumber = styled.a`
	text-decoration: none;
	display: flex;
	align-items: center;
	padding: ${gap[24]} ${gap[0]} ${gap[32]};
	border-bottom: 2px solid var(--primaryBackground);
	color: var(--black);
`;

const PhoneNumber__Icon = styled.div`
	width: 18px;
	height: 18px;
	margin-right: ${gap[12]};

	${above(
		breakpoints[600],
		() => css`
			width: 24px;
			height: 24px;
		`
	)}
`;

const GDPR = styled(xsBodyLight)`
	margin-top: ${gap[16]};
	max-width: none;

	${above(
		breakpoints[600],
		() => css`
			margin-top: ${gap[24]};
		`
	)}
`;

const BodyRegular = styled(smBodyRegular)`
	margin-bottom: ${gap[24]};
	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

export default {
	Contact,
	Content,
	Inner,
	Title,
	Body,
	BodyBook,
	BodyRegular,
	Form,
	FormTitle,
	PhoneNumber,
	PhoneNumber__Icon,
	GDPR,
};
